import React, {Component} from 'react';
import ProjectEntry from "./projectEntry";
import {Constants} from "../constants";
import '../css/stuff.css';

// Deploy new project sequence:
// https://console.firebase.google.com/u/0/


class TheRestInMotionSite extends Component {
    render() {
        return (
            <div className={'site-wrapper'}>
                <h1>The Rest In Motion</h1>
                <ul>
                    <li>
                        <ProjectEntry
                            name={'DUKX'}
                            link={'https://play.google.com/store/apps/details?id=app.dukxrelease.twa'}
                            date={new Date(2021, 1)} // Feb
                            description={'A puzzle game with a novel clean logical structure. Initially conceived in ' +
                            '2017, it took me a while to develop and flesh out the idea.'}
                        />
                    </li>
                    <li>
                        <ProjectEntry
                            name={'Optimistic Minesweeper'}
                            link={'https://minesweeper.therestinmotion.com'}
                            codelink={'https://github.com/kord/optimistic-minesweeper'}
                            date={new Date(2021, 4)} // May
                            description={'A variant of minesweeper where if your situation really demands that you ' +
                            'guess, the minefield gets rewritten to a consistent state where your guess succeeds. ' +
                            'Also, as essential intermediate work, a minesweeper that plays itself, like a ' +
                            'screensaver. 43% success rate on classic expert mode, not quite the state of the art.'}
                        />
                    </li>
                    <li>
                        <ProjectEntry
                            name={'Soundcheck'}
                            link={'https://soundcheck.therestinmotion.com'}
                            wip={true}
                            date={new Date(2021, 5)} // June
                            description={'Incomplete demo, attempting to develop visualizations of microphone ' +
                            'pitch changes.'}
                        />
                    </li>
                    <li>
                        <ProjectEntry
                            name={'Kuromasu'}
                            link={'https://kuromasu.therestinmotion.com'}
                            date={new Date(2021, 6)} // July
                            description={'Kuromasu puzzle. Essentially a clone of 0h n0, but with a SAT solver ' +
                            'hooked up.'}
                        />
                    </li>
                    <li>
                        <ProjectEntry
                            name={'Light Up'}
                            link={'https://lightup.therestinmotion.com'}
                            date={new Date(2021, 6)} // July
                            description={'Light Up puzzle. Another classic Nikoli puzzle.'}
                        />
                    </li>
                    <li>
                        <ProjectEntry
                            name={'Wordle'}
                            link={'https://wordle.therestinmotion.com'}
                            codelink={'https://github.com/kord/wordle-clone'}
                            date={new Date(2022, 0)} // January
                            description={'Clone of the viral Word puzzle Wordle. Word lengths of 4-7 provided.'}
                        />
                    </li>
                    <li>
                        <ProjectEntry
                            name={'Floaters'}
                            wip={true}
                            link={'https://floaters.therestinmotion.com'}
                            date={new Date(2022, 1)} // Feb
                            description={'Making some inert dots accelerate strategically to form nice patterns.'}
                        />
                    </li>
                    <li>
                        <ProjectEntry
                            name={'Dungeons and Diagrams'}
                            wip={false}
                            link={'https://dandd.therestinmotion.com'}
                            codelink={'https://github.com/kord/DungeonsAndDiagrams'}
                            date={new Date(2023, 0)} // Jan
                            description={'Reproduction of a puzzle scheme originated by Zachtronics. ' +
                            'Really needs a mouse to work well.'}
                        />
                    </li>
                    <li>
                        <ProjectEntry
                            name={'Word Matcher'}
                            wip={true}
                            link={'https://wordmatcher.therestinmotion.com'}
                            date={new Date(2023, 11)} // December
                            description={'Simple game for learning Chinese. Options are provided for learning HSK' +
                                ' word lists, most common words, differences between simplified and traditional ' +
                                'characters, with more to come.'}
                        />
                    </li>
                </ul>

                <div className={'footer'}>
                    I can be contacted at
                    <a href={Constants.emailUrl} className={'email-link'}>
                        TheRestInMotion@gmail.com
                    </a>.
                </div>
            </div>
        );
    }
}

export default TheRestInMotionSite;