import React from 'react';
import './App.css';
import TheRestInMotionSite from "./components/theRestInMotionSite";

function App() {
  return (
      <TheRestInMotionSite/>
  );
}

export default App;
