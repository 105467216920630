import React, {Component} from 'react';
import '../css/projectEntry.css'


interface ProjectEntryProps {
    name: string,
    link: string,
    codelink?: string,
    wip?: boolean,
    date?: Date,
    description?: string,
}

interface ProjectEntryState {
}

class ProjectEntry extends Component<ProjectEntryProps, ProjectEntryState> {

    private dateString() {
        let d = this.props.date;
        let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
        let mo = new Intl.DateTimeFormat('en', {month: 'long'}).format(d);
        return `${mo} ${ye}`;
    }

    render() {
        return (
            <div className={'project-entry'}>
                <a href={this.props.link} className={'project-name-link'}>{this.props.name}</a>
                &nbsp;
                {this.props.date ?
                    <div className={'project-date-string'}>({this.dateString()})</div> :
                    <></>}
                {this.props.wip ? <>&nbsp;(Work in progress)</> : <></>}
                {this.props.codelink ? <>&nbsp;(<a href={this.props.codelink}>code</a>)</> : <></>}
                {this.props.description ?
                    <>
                        <br/>
                        <div className={'project-description'}>{this.props.description}</div>
                    </> :
                    <></>}
            </div>
        );
    }
}

export default ProjectEntry;